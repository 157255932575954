<template>
  <div
    class="permissionsBox item-b"
    v-loading="loadingData"
    element-loading-text="拼命加载中"
  >
    <!-- 组件 -->
    <FormItem
      :formList="formList"
      :btn="btn"
       :operShow="operShow"
      @search="onSearch"
      @add="onAdd"
      @reset="onReset"
      :btnObj="btnObj"
      :search="search"
    />
    <Table
      ref="table"
      :tableList="tableList"
      :tableData="tableData"
      @operation="onHandle"
      @currentNum="current"
      :total="total"
    />
    <!-- 抽屉模块 -->
    <el-drawer
      custom-class="drawer-class"
      :visible.sync="show"
      :show-close="false"
      :withHeader="false"
      :wrapperClosable="false"
    >
      <div class="drawer-box flex-col">
        <div class="title" v-if="!isEdit">
          <span class="rs"> </span> <span class="re"></span>新增报修<span
            class="ra"
          >
          </span>
          <span class="rb"> </span>
        </div>
        <div class="title" v-else>
          <span class="rs"> </span> <span class="re"> </span>
          编辑报修
          <span class="ra"> </span> <span class="rb"> </span>
        </div>
        <div class="table auto">
          <el-form
            :model="postFormData"
            size="small"
            :rules="postFormDataRules"
            label-position="left"
            label-width="auto"
            ref="addForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="公厕名称：">
              <!-- <el-input
                v-if="!isEdit"
                placeholder="请输入公厕名称"
                v-model="postFormData.videoName"
                class="custom-submit-input"
              ></el-input> -->
              <el-select v-if="!isEdit" v-model="gongcevalue" clearable  placeholder="请选择" @change="changegongce">
                <el-option
                  v-for="item in gongceList"
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id">
                </el-option>
              </el-select>
              <span v-else>{{ postFormData.videoName }}</span>
            </el-form-item>
            <el-form-item label="申报人：" prop="siteName">
              <el-input
                v-if="!isEdit"
                placeholder="请输入申报人："
                v-model="postFormData.siteName"
                class="custom-submit-input"
                :disabled="true"
              ></el-input>
              <span v-else-if="postFormData.siteName !== 'null'">{{ postFormData.siteName }}</span>
            </el-form-item>
            <el-form-item v-if="isEdit" label="申报时间：" prop="videoModel">
              <span>{{ postFormData.videoType }}</span>
            </el-form-item>
            <el-form-item label="申报原因：" prop="Reasonsdeclaration">
              <el-input
                v-if="!isEdit"
                placeholder="请输入申报原因"
                v-model="postFormData.Reasonsdeclaration"
                type="textarea"
                :rows="4"
                class="custom-submit-input"
              ></el-input>
              <span v-else>{{ postFormData.siteType }}</span>
            </el-form-item>
            <el-form-item v-if="isEdit" label="维修人：" prop="Repairman">
              <el-input
                placeholder="请输入维修人"
                v-model="postFormData.Repairman"
                class="custom-submit-input"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="isEdit"
              label="维修详情："
              prop="Maintenancedetails"
            >
              <el-input
                placeholder="请输入维修详情"
                v-model="postFormData.Maintenancedetails"
                type="textarea"
                :rows="4"
                class="custom-submit-input"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="站点类型：" prop="siteType">
              <el-select
                v-model="postFormData.siteType"
                placeholder="请选择站点类型"
                class="custom-submit-select"
                @change="handleRole"
              >
                <el-option
                  v-for="item in siteTypes"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="站点名称：" prop="siteName">
              <el-select
                v-model="postFormData.siteName"
                placeholder="请选择站点"
                class="custom-submit-select"
                @change="handleDepartment"
              >
                <el-option
                  v-for="item in departmentOptions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备类型:" prop="videoType">
              <el-select
                v-model="postFormData.videoType"
                placeholder="请选择设备"
                class="custom-submit-select"
                @change="handleDepartment"
              >
                <el-option
                  v-for="item in videoTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="设备通道：" prop="channelNo">
              <el-input
                v-if="!isEdit"
                placeholder="请输入设备通道"
                v-model="postFormData.channelNo"
                class="custom-submit-input"
              ></el-input>
              <span v-else>{{ postFormData.channelNo }}</span>
            </el-form-item> -->
          </el-form>
        </div>
        <div class="c-center btn">
          <el-button
            type="primary submit-primary"
            v-if="!isEdit"
             :disabled="operShow"
            @click="saveAdd"
            >保存</el-button
          >
          <el-button type="primary submit-primary" v-else @click="update" :disabled="operShow"
            >保存</el-button
          >
          <el-button type="info submit-info" @click="cancelAdd">取消</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 详情抽屉 -->
    <el-drawer
      custom-class="drawer-class"
      :visible.sync="detailshow"
      :show-close="false"
      :withHeader="false"
      :wrapperClosable="true"
    >
      <div class="drawer-box flex-col">
       
        <div class="title">
          <span class="rs"> </span> <span class="re"> </span>
          报修详情
          <span class="ra"> </span> <span class="rb"> </span>
        </div>
        <div class="table auto">
          <el-form
            :model="postFormData"
            size="small"
            
            label-position="left"
            label-width="auto"
            ref="addForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="公厕名称：">
             
             
              <span >{{ postFormData.videoName }}</span>
            </el-form-item>
            <el-form-item label="申报人：" prop="siteName">
             
              <span v-if="postFormData.siteName !== 'null'">{{ postFormData.siteName }}</span>
            </el-form-item>
            <el-form-item  label="申报时间：" prop="videoModel">
              <span>{{ postFormData.videoType }}</span>
            </el-form-item>
            <el-form-item label="申报原因：" prop="Reasonsdeclaration">
             
              <span >{{ postFormData.siteType }}</span>
            </el-form-item>
            <el-form-item  label="维修人：" prop="Repairman">
             
              <span>{{postFormData.Repairman}}</span>
            </el-form-item>
             <el-form-item  label="维修时间：" prop="Repairman">
             
              <span>{{postFormData.Maintenancetime}}</span>
            </el-form-item>
             <el-form-item  label="维修时长：" prop="Repairman">
             
              <span>{{postFormData.maintenanceDuring}}</span>
            </el-form-item>
            <el-form-item label="维修详情：" prop="Maintenancedetails">
              
              <span>{{postFormData.Maintenancedetails}}</span>
            </el-form-item>

          </el-form>
        </div>
        <div class="c-center btn">
         
          <!-- <el-button type="primary submit-primary" @click="detailupdate"
            >保存</el-button
          >
          <el-button type="info submit-info" @click="cancelAdd">取消</el-button> -->
        </div>
      </div>
    </el-drawer>




  </div>
</template>

<script>
import axios from "@/request";
export default {
  name: "WashroomRepairQuote",
  data() {
    return {
      operShow:false,
      loadingData: true,
      formList: [
        {
          props: "toiletName",
          el: "el-input",
          label: "设备名称",
          placeholder: "请输入公厕名称",
        },
        {
          props: "status",
          el: "el-select",
          label: "",
          placeholder: "请选择当前状态",
          options: [
            { value: "2", label: "已维修" },
            { value: "1", label: "已报修" },
          ],
        },
      ],
      btnObj: {
        search: "查询",
        add: "新增",
        reset: "重置",
      },
      Name:'5555',
      btn: "search,add,reset",
      tableList: [
        {
          prop: "index",
          label: "序号",
          width: "80px",
        },
        {
          prop: "toiletName",
          label: "公厕名称",
          width: "",
        },
        {
          prop: "status",
          label: "当前状态",
          width: "100px",
          color(val) {
            let color = "";
            switch (val) {
              case 1:
                color = "#FF693B";
                break;
              case 2:
                color = "#1BDC31";
                break;
            }
            return color;
          },
          formatter(val) {
            let str = "";
            switch (val.status) {
              case 1:
                str = "已报修";
                break;
              case 2:
                str = "已维修";
                break;
              default:
                str = "暂无数据";
                break;
            }
            return str;
          },
        },
        {
          prop: "createEmpName",
          label: "申报人",
          width: "",
        },
        {
          prop: "createTime",
          label: "申报时间",
          width: "",
        },
        {
          prop: "reason",
          label: "申报原因",
          width: "",
        },
        {
          prop: "repairEmp",
          label: "维修人",
          width: "",
        },
        {
          prop: "repairTime",
          label: "维修时间",
          width: "",
        },
        {
          prop: "repairDetails",
          label: "维修详情",
          width: "",
        },
        {
          prop: "repairDuration",
          label: "维修时长",
          width: "",
        },
        {
          prop: "op",
          label: "操作",
          width: "150",
          btn: [
            {
              type: "primary",
              name: "详情",
              prop: "edit",
              isHide(data) {
                return data.status == 2 ? false : true;
              },
            },
            {
              type: "danger",
              name: "编辑",
              prop: "bianji",
               isHide(data) {
                return data.status == 1 ? false : true;
              },
            },
          ],
        },
      ],
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      search: {
        toiletName: '',
        status: '',
      },
      show: false,
      detailshow:false,
      departmentOptions: [],
      siteTypes: [
        { id: "1", value: "公厕" },
        { id: "2", value: "转运站" },
      ],
      videoTypeList: [
        { id: "0", value: "球机" },
        { id: "1", value: "枪机" },
        { id: "2", value: "语音设备" },
      ],
      postFormData: {
        id: "",
        videoName: "",
        siteType: "",
        Repairman: "", // 维修人
        siteName: "11111",
        Maintenancetime: "",// 维修时间
        maintenanceDuring: "", //维修时长
        videoType: "",
        videoModel: "",
        channelNo: "",
        Maintenancedetails: "",// 
        Reasonsdeclaration: "",// 申报原因
      },
      postFormDataRules: {
        videoName: [
          { required: true, message: "请输入公厕名称" },
          { max: 20, message: "最多20个字符" },
        ],
        siteType: [{ required: true, message: "请输入申报原因" }],
        siteName: [{ required: true, message: "请输入申报人" }],
        Repairman: [{ required: true, message: "请输入维修人" }],
        Maintenancedetails: [{ required: true, message: "请输入维修详情" }],
        // Reasonsdeclaration: [{ required: true, message: "请输入申报原因" }],
        // videoModel: [{ required: true, message: "请输入序列号" }],
        // channelNo: [
        //   { required: true, message: "请输入通道号" },
        //   { tyep: "regexp", pattern: /^\d+$/, message: "请输入正确的通道号" },
        // ],
      },
      isEdit: false,
      gongcevalue: '',
      gongceList: [],
      changegongcemane: '',
      Detailstype: false,
    };
  },
 
  mounted() {
    if (sessionStorage.getItem('username') == 'yanshi') {
      this.operShow = true
    }
    this.getData(this.page, this.size, this.search);
    setTimeout(() => {
      this.loadingData = false;
    }, 800);
    this.getPublicbetalist()
  
  },
  methods: {
    // 下拉框请求
    department(url) {
      axios.get(url).then((res) => {
        this.departmentOptions = res.map((item) => {
          return {
            id: item.id,
            value: item.siteName,
          };
        });
      });
    },
    getData(page, size, val) {
      axios
        .get(`/toiletGuarantee/selectPageList/${page}/${size}`, {
          params: {
            ...val,
          },
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.list;
          this.total = res.total;
        });
    },
    // 获取公厕列表
    getPublicbetalist(){
       axios.get(`/toilet/findAll`, {})
        .then((res) => {
          
          this.gongceList = res
          console.log(this.gongceList)
        });
    },
    // 选择公厕
    changegongce(val){
      console.log(val)
      console.log(this.gongcevalue)

      let id = this.gongceList.find(res=> {
        if(val == res.id){
          return res
        }
      })
      this.changegongcemane = id.siteName

    },

    onSearch(val) {
      console.log(val);
      this.search.toiletName = val.toiletName
      this.search.status = val.status
      this.getData(this.page, this.size, val);
    },
    onAdd(val) {
      let id  = sessionStorage.getItem("username") || localStorage.getItem("username")
      this.postFormData.siteName = id
      // this.$set(this.postFormData,"siteName",id)
      console.log(this.postFormData)
      // console.log(this.postFormData.siteName)
      this.show = true;
      this.isEdit = false;
      // this.postFormData = Object.assign({}, {});
    },
    onReset(val) {
      this.$refs.table.lastEmittedPage();
      this.getData(1, 10, val);
    },
    // 表格操作按钮
    onHandle(data) {
      console.log(data)
      // opVal ：执行表格编辑删除等操作
      if (data.opVal == "del") {
        this.del(data.data);
      } else if (data.opVal == "bianji") {
        this.edit(data.data);
      } else if (data.opVal == "edit") {
        this.bianji(data.data);
      }
    },
    // 分页事件
    current(val) {
      let page = val;
      console.log(val)
      console.log(this.search)
      this.getData(page, this.size, this.search);
    },
    cancelAdd() {
      // this.$refs.addForm.resetFields();
      this.show = false;
      this.detailshow = false
    },
    bianji(data){
      this.detailshow = true
      this.postFormData = Object.assign({}, data);
      this.postFormData.videoName = `${data.toiletName}`;
      this.postFormData.siteName = `${data.createEmpName}`;
      this.postFormData.siteType = `${data.reason}`;
      this.postFormData.videoType = `${data.createTime}`
      this.postFormData.Repairman = `${data.repairEmp}`
      this.postFormData.Maintenancedetails = `${data.repairDetails}`
      this.postFormData.Maintenancetime = `${data.repairTime}`
      this.postFormData.maintenanceDuring = `${data.repairDuration}`
    },
    edit(data) {
      console.log(data);
      this.isEdit = true;
      this.show = true;
      this.postFormData = Object.assign({}, data);
      this.postFormData.videoName = `${data.toiletName}`;
      this.postFormData.siteName = `${data.createEmpName}`;
      this.postFormData.siteType = `${data.reason}`;
      (this.postFormData.videoType = `${data.createTime}`),
        (this.postFormData.id = `${data.id}`);
      if (data.siteType == "1") {
        this.postFormData.siteName = data.toiletId;
        this.department("/toilet/findAll");
      } else if (data.siteType == "2") {
        this.postFormData.siteName = data.transferPointId;
        this.department("/transferPoint/findAll");
      }
    },
    //下拉框切换类型
    handleRole(val) {
      if (val == "1") {
        this.postFormData.toiletId = this.postFormData.siteName;
        this.department("/toilet/findAll");
      } else if (val == "2") {
        this.postFormData.transferPointId = this.postFormData.siteName;
        this.department("/transferPoint/findAll");
      }
      // let roleObj = this.roleOptions.find(item => {
      //   return item.value === val
      // })
      // this.$set(this.postFormData,'roleId',roleObj.value)
      // this.$set(this.postFormData,'roleName',roleObj.label)
    },
    handleDepartment(val) {
      // let departmenObj = this.departmentOptions.find(item => {
      //   return item.value === val
      // })
      // this.$set(this.postFormData,'departmentId',departmenObj.value)
      // this.$set(this.postFormData,'departmentName',departmenObj.label)
    },
    saveAdd() {
      
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          console.log(this.postFormData)
          let {  Reasonsdeclaration, id } = this.postFormData;
          let toiletName = this.changegongcemane;
          let createEmp = sessionStorage.getItem("id") || localStorage.getItem("id");
          let reason = Reasonsdeclaration;
          let toiletId = this.gongcevalue;
          let dataObj = { toiletName, createEmp, reason, toiletId };
          console.log(dataObj)
          if (this.postFormData.siteType == "1") {
            dataObj.toiletId = this.postFormData.siteName;
            delete dataObj.transferPointId;
          } else if (this.postFormData.siteType == "2") {
            dataObj.transferPointId = this.postFormData.siteName;
            delete dataObj.toiletId;
          }

          axios.post("/toiletGuarantee/insert", { ...dataObj }).then((res) => {
            console.log(res);
            if (res == null) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.cancelAdd();
              this.getData(this.page, this.size, this.search);
            } else {
              this.$message({
                message: "新增失败",
                type: "error",
              });
            }
          });
          this.postFormData.Reasonsdeclaration = ''
          this.gongcevalue = ''
          this.changegongcemane = ''
        }
       
      });
    },
    detailupdate(){
      this.detailshow = false

    },
    update() {
      console.log(this.postFormData);
      this.$refs.addForm.validate((valid) => {
        console.log()
        if (valid) {
          let { id } = this.postFormData;
          let repairEmp = this.postFormData.Repairman
          let repairDetails = this.postFormData.Maintenancedetails
          let dataObj = { id,repairEmp, repairDetails };
          // if (this.postFormData.siteType == "1") {
          //   dataObj.toiletId = this.postFormData.siteName;
          //   delete dataObj.transferPointId;
          // } else if (this.postFormData.siteType == "2") {
          //   dataObj.transferPointId = this.postFormData.siteName;
          //   delete dataObj.toiletId;
          // }
          axios.post("/toiletGuarantee/update", { ...dataObj }).then((res) => {
            if (res == null) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.cancelAdd();
              this.getData(this.page, this.size, this.search);
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
              });
            }
          });
        }
      });
    },
    del(data) {
      this.$confirm("此操作将永久删除此设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios.post(`/video/deleteById/${data.id}`).then((res) => {
            if (res == null) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getData(this.page, this.size, this.search);
            } else {
              this.$message({
                message: "删除失败",
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.permissionsBox {
  width: 100%;
  .input {
    width: 100%;
  }
  .drawer-box {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    .title {
      position: relative;
      padding: 20px;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      border-bottom: solid 1px #dadada;
    }
    .table {
      padding: 20px;
      overflow: auto;
    }
    .btn {
      padding: 30px 0;
    }
  }
}
// // 弹窗头部样式
// .re,.rs{
//         display: inline-block;
//         border-top: solid 6px  #2980FF;
//         border-radius:32% ;
//         width: 20px;
//         transform:rotate(120deg);
//         -ms-transform:rotate(120deg); /* Internet Explorer 9*/
//         -moz-transform:rotate(120deg); /* Firefox */
//         -webkit-transform:rotate(120deg); /* Safari 和 Chrome */
//         -o-transform:rotate(120deg); /* Opera */
//         filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
//         position: absolute;
//         top: 48%;
//         left: 34%;
//     }
//     .rs{
//         border-top: solid 6px #3F8CFF;
//         width: 13px;
//         top: 50%;
//         left: 30%;
//     }
//     .ra,.rb{
//         display: inline-block;
//         border-top: solid 6px  #2980FF;
//         border-radius:32% ;
//         width: 20px;
//         transform:rotate(60deg);
//         -ms-transform:rotate(60deg); /* Internet Explorer 9*/
//         -moz-transform:rotate(60deg); /* Firefox */
//         -webkit-transform:rotate(60deg); /* Safari 和 Chrome */
//         -o-transform:rotate(60deg); /* Opera */
//         filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
//         position: absolute;
//         top: 48%;
//         right: 34%;
//     }
//     .rb{
//         border-top: solid 6px #3F8CFF;
//         width: 13px;
//         top: 50%;
//         right: 30%;
//     }
</style>
